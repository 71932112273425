<template>
  <v-dialog
    v-model="dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="green"
        class="pr-4 ma-2"
        @click="get_procedimentos()"
      ><v-icon class="mx-2 white--text">mdi-eye-outline</v-icon></v-btn>
    </template>
    <v-card
      class="my-auto"
      :loading="loading_procedimentos"
    >
      <v-card-title>
        <v-row>
          <v-col cols="2">
            Cod: <b>{{item.cd_paciente}}</b> 
          </v-col>
          <v-col cols="9">  
            Paciente: <b>{{item.nm_paciente}}</b>
          </v-col>
          <v-col cols="1" class="text-right">  
            <v-btn @click="dialog=false" icon><v-icon>mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card>
        <v-card-text>
          <v-data-table
            :items="procedimentos"
            :headers="headers"
            :lazy="true" @update:page="get_procedimentos"
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
  </v-dialog>
</template>   
<script>

import AppEtlService from '@/service/AppEtlService'


const api = new AppEtlService();
export default {
  name:"Detalhes",
  props:{
    item:Object
  },
  data:()=>({
    dialog:false,
    loading_procedimentos:false,
    procedimentos:[],
    headers:[
      { text: 'ATENDIMENTO', align: 'start', sortable: false, value: 'cd_atendimento'},
      { text: 'EMPRESA', align: 'start', sortable: false, value: 'empresa'},
      { text: 'CONVENIO', align: 'start', sortable: false, value: 'nm_convenio'},
      { text: 'ORI_ATE', align: 'start', sortable: false, value: 'ds_ori_ate'},
      { text: 'DATA', align: 'start', sortable: false, value: 'dt_atendimento'},
      { text: 'PRESTADOR', align: 'start', sortable: false, value: 'nm_prestador'},
      { text: 'ATENDIMENTO', align: 'start', sortable: false, value: 'tp_atendimento'},
      /*
      { text: 'DES_ATE', align: 'start', sortable: false, value: 'cd_des_ate'},
      { text: 'TIPO_INTERNACAO', align: 'start', sortable: false, value: 'cd_tipo_internacao'},
      */
      { text: 'RETORNO', align: 'start', sortable: false, value: 'sn_retorno'},
      { text: 'COD PROC.', align: 'start', sortable: false, value: 'cd_pro_int'},
      { text: 'PROCEDIMENTOS', align: 'start', sortable: false, value: 'procedimentos'},
    ],
  }),
  methods:{
    async get_procedimentos(){
      this.loading_procedimentos = true
      return await api
        .get_query('get_procedimentos',[this.item.nr_cpf])
        .then( response => { 
          this.procedimentos = response.data;
        })
        .catch( error => { console.log(error)})
        .finally( ()=>{ this.loading_procedimentos = false})
    }
  }
}
</script>